import React, { useRef, useEffect, useState } from "react";

const App = () => {
  const iframeRef = useRef(null);
  const [hookUrl, setHookUrl] = useState("https://app.one52.ae/webhook/");

  useEffect(() => {
    // Dynamically set hookUrl based on query parameters
    const urlParams = new URLSearchParams(window.location.search);
    const customHook = urlParams.get("hook_url");
    if (customHook) setHookUrl(decodeURIComponent(customHook));
  }, []);

  const iframeSrc = `https://ui.pcon-solutions.com/#GATEKEEPER_ID=5f913d7720063&lang=en&HOOK_URL=${encodeURIComponent(
    hookUrl
  )}`;

  // Function to send lead data to the iframe
  const sendLeadData = () => {
    if (iframeRef.current) {
      const leadData = {
        type: "lead",
        data: {
          id: "12345",
          name: "Jane Doe",
          email: "jane.doe@example.com",
          phone: "+1234567890",
          customFields: {
            interest: "Modern Furniture",
            budget: "$10,000",
          },
        },
      };

      // Sending lead data using postMessage
      iframeRef.current.contentWindow.postMessage(leadData, "https://ui.pcon-solutions.com");
      console.log("Sent lead data to iframe:", leadData);
    }
  };

  useEffect(() => {
    const handleMessage = async (event) => {
      // Verify the origin of the message
      if (event.origin !== "https://ui.pcon-solutions.com") {
        console.warn("Message from unauthorized origin:", event.origin);
        return;
      }

      if (event.data.type === "leadResponse") {
        console.log("Lead response received from iframe:", event.data);
      } else {
        console.log("Message received:", event.data);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  // Example API call to ensure proper headers are used
  const makeApiCall = async () => {
    try {
      const response = await fetch("https://ui.pcon-solutions.com", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key: "value" }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("API Response:", data);
    } catch (error) {
      console.error("Error making API call:", error.message);
    }
  };

  return (
    <div>
      <h1>pCon Solutions UI - Lead Integration</h1>
      <p>Using HOOK_URL: {hookUrl}</p>
      <iframe
        ref={iframeRef}
        src={iframeSrc}
        title="pCon UI"
        width="100%"
        height="600px"
        style={{ border: "none" }}
      ></iframe>
      <button onClick={sendLeadData} style={{ marginTop: "20px" }}>
        Send Lead Data to pCon UI
      </button>
      <button onClick={makeApiCall} style={{ marginTop: "20px" }}>
        Make API Call
      </button>
    </div>
  );
};

export default App;
